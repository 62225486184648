// ===========================================================================
// *** Custom breakpoint mixins | JCU
// ===========================================================================

// Create breakpoint shortcuts, similar to BS4 utilities, but rebuilt without
// SCSS maps to allow for older CSS implementation. Adds ability to generate
// custom breakpoints.

@mixin breakpoint-up($breakpoint-name) {
  $breakpoint-min-width: 0;

  // Mimic behavior of SCSS maps, due to older SCSS version.
  @if $breakpoint-name == 'xs' {
    $breakpoint-min-width: $screen-xs-min;
  } @else if $breakpoint-name == 'sm' {
    $breakpoint-min-width: $screen-sm-min;
  } @else if $breakpoint-name == 'md' {
    $breakpoint-min-width: $screen-md-min;
  } @else if $breakpoint-name == 'lg' {
    $breakpoint-min-width: $screen-lg-min;
  } @else if $breakpoint-name == 'xl' {
    $breakpoint-min-width: $screen-xl-min;
  } @else if type-of($breakpoint-name) == 'number' {
    $breakpoint-min-width: $breakpoint-name; // Can pass custom breakpoints (e.g.`560px`)
  }

  @media screen and (min-width: $breakpoint-min-width) {
    @content;
  }
}

@mixin breakpoint-down($breakpoint-name) {
  $breakpoint-max-width: $screen-xxs-max;

  // Mimic behavior of SCSS maps, due to older SCSS version.
  @if $breakpoint-name == 'xs' {
    $breakpoint-max-width: $screen-xs-max;
  } @else if $breakpoint-name == 'sm' {
    $breakpoint-max-width: $screen-sm-max;
  } @else if $breakpoint-name == 'md' {
    $breakpoint-max-width: $screen-md-max;
  } @else if $breakpoint-name == 'lg' {
    $breakpoint-max-width: $screen-lg-max;
  } @else if type-of($breakpoint-name) == 'number' {
    $breakpoint-max-width: $breakpoint-name; // Can pass custom breakpoints (e.g.`560px`)
  }

  @media screen and (max-width: $breakpoint-max-width) {
    @content;
  }
}
