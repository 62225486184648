// ===========================================================================
// *** Animations
// ===========================================================================

// We should add the keyframes here so then the animation can be re use it in different places
// Example usage:

/*
.header__action_list_item_profile-pic:not(.active):hover {
  box-shadow: 0 0 0 0 white;
  animation: white-pulse 2s infinite;
}
*/
// TODO: Add real values here


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ring {
  0% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-25deg);
  }
  60% {
    transform: rotate(20deg);
  }
  80% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes white-pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.0);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes showIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
