// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import '../../../../styles/variables/_breakpoints.scss';
@import '../../../../styles/variables/_colors.scss';
@import '../../../../styles/variables/_spacers.scss';
@import '../../../../styles/variables/_typography.scss';
@import '../../../../styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import '../../../../styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import '../../../../styles/mixins/_breakpoints.scss';
@import '../../../../styles/mixins/_form-helpers.scss';

.checkbox {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: -5px;
  > * {
    margin: 5px;
  }
}

.checkbox_control {
  display: inline-grid;
  width: 1em;
  height: 1em;
  border: 1px solid var(--light-dark);
  transition: border-color 200ms, background 200ms;
  svg {
    opacity: 0;
    transition: opacity 200ms;
    color: white;
  }
}

.checkbox_input {
  display: grid;
  grid-template-areas: 'checkbox';
  place-content: center center;
  cursor: pointer;

  > * {
    grid-area: checkbox;
  }

  input {
    opacity: 0;
    width: 1em;
    height: 1em;
    cursor: pointer;

    &:checked + .checkbox_control {
      border-color: var(--primary);
      background: var(--primary);
      svg {
        opacity: 1;
      }
    }
    &:disabled + .checkbox_control,
    &:checked:disabled + .checkbox_control {
      color: var(--dark-light);
    }
  }
}

.checkbox--disabled {
  color: var(--dark-light);
}

.checkbox_error {
  color: var(--error);
}
