// Generate all css variables for default theme `var(--primary)`
@mixin create-css-variables($project-colors) {
  :root {
    @each $color-name, $hex-value in $project-colors {
      --#{$color-name}: #{$hex-value};
    }
  }
}

@include create-css-variables($project-colors);

// ===========================================================================
// *** Multiple Themes
// ===========================================================================
// This is optional, if you don't have dark or light theme let the default values in false and don't touch this code

$dark-theme: false !default;
$light-theme: false !default;

@mixin create-css-variables-dark-theme($project-colors) {
  body.dark {
    @each $color-name, $hex-value in $project-colors {
      --#{$color-name}: #{darken($hex-value, 60%)};
    }
  }
}

@mixin create-css-variables-light-theme($project-colors) {
  body.light {
    @each $color-name, $hex-value in $project-colors {
      --#{$color-name}: #{lighten($hex-value, 60%)};
    }
  }
}
@if $dark-theme {
  @include create-css-variables-dark-theme($project-colors);
}

@if $light-theme {
  @include create-css-variables-light-theme($project-colors);
}
