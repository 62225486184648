// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import '../../../../styles/variables/_breakpoints.scss';
@import '../../../../styles/variables/_colors.scss';
@import '../../../../styles/variables/_spacers.scss';
@import '../../../../styles/variables/_typography.scss';
@import '../../../../styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import '../../../../styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import '../../../../styles/mixins/_breakpoints.scss';
@import '../../../../styles/mixins/_form-helpers.scss';

.ContentContainer {
  display: grid;
  overflow: hidden;
  height: var(--viewportHeight);
  grid-template-columns: 1fr;
  grid-template-rows: $nav-size 1fr;
  @include breakpoint-up(sm) {
    grid-template-columns: initial;
    grid-template-rows: initial;
    align-content: flex-start;
  }
}

$sidePaddings: 15px;
$sidePaddingsPlusRowGutters: 15px + $sidePaddings;

.well {
  max-width: 312px + $sidePaddingsPlusRowGutters * 2;
  text-align: center;
  padding-right: $sidePaddingsPlusRowGutters;
  padding-left: $sidePaddingsPlusRowGutters;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.condenseMobileVertical {
  @include breakpoint-down('xs') {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
}

.well__larger {
  max-width: 400px + $sidePaddings * 2;
}

$headerTitleBarHeight: 96px;
$spaceAtTheBottomOfScreenHeight: 60px;

.wellWrapper {
  flex-grow: 1;
  @include breakpoint-up(sm) {
    height: calc(var(--viewportHeight) - #{$nav-size} - #{$headerTitleBarHeight} - #{$spaceAtTheBottomOfScreenHeight});
  }
}

.wellWrapper__hasNoTitle {
  @include breakpoint-up(sm) {
    height: calc(var(--viewportHeight) - #{$nav-size} - #{$spaceAtTheBottomOfScreenHeight});
  }
}
