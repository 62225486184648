// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import '../../../../styles/variables/_breakpoints.scss';
@import '../../../../styles/variables/_colors.scss';
@import '../../../../styles/variables/_spacers.scss';
@import '../../../../styles/variables/_typography.scss';
@import '../../../../styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import '../../../../styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import '../../../../styles/mixins/_breakpoints.scss';
@import '../../../../styles/mixins/_form-helpers.scss';

.Logo {
  display: inline-block;
  height: $nav-size;
  max-width: 80px;
  padding: 0.75rem 0;
}

.AppNav {
  display: none;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  max-height: $nav-size;
  justify-content: flex-end;
  color: var(--secondary-dark);

  .Logo {
    display: none;

    @include breakpoint-up('sm') {
      display: inline-block;
      position: relative;
      max-height: $nav-size;
      height: 100%;
      box-sizing: border-box;
      padding: 0.75rem 0;
    }
  }

  @include breakpoint-down('xs') {
    background: var(--light-light);
  }

  @include breakpoint-up('sm') {
    display: flex;
    color: var(--light);
    justify-content: space-between;
  }
}

.AppNav__forceNavbarMobile {
  display: flex;
}