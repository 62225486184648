// ===========================================================================
// *** Fonts
// ===========================================================================

// Google fonts
// =====================================================================
// TODO: Add real values here

@font-face {
  font-family: 'Proxima Nova';
  src: url('./Proxima Nova/Proxima Nova - Semi Bold.woff2') format('woff2'),
    url('./Proxima Nova/Proxima Nova - Semi Bold.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./Proxima Nova/Proxima Nova - Light.woff2') format('woff2'),
    url('./Proxima Nova/Proxima Nova - Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./Proxima Nova/Proxima Nova - Regular.woff2') format('woff2'),
    url('./Proxima Nova/Proxima Nova - Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./Proxima Nova/Proxima Nova - Italic.woff2') format('woff2'),
    url('./Proxima Nova/Proxima Nova - Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./Proxima Nova/Proxima Nova - Bold.woff2') format('woff2'),
    url('./Proxima Nova/Proxima Nova - Bold.woff') format('woff');
  font-weight: 700;
}
