// ===========================================================================
// *** Box shadows
// ===========================================================================

// Usually the Design team define a limited number of different box shadows to generate a height of some elements.
// We should standardize the different options

// TODO: Add real values here
%modal-drop-shadow,
.modal-drop-shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.input-drop-shadow {
  box-shadow: 0 -5px 5px -10px var(--primary) inset;
  &:focus {
    box-shadow: 0 -5px 5px -5px var(--primary) inset;
  }
}

.input-drop-shadow--error {
  box-shadow: 0 -5px 5px -10px var(--error) inset;
  &:focus {
    box-shadow: 0 -5px 5px -5px var(--error) inset;
  }
}
