// ===========================================================================
// *** Spacer variables
// ===========================================================================

// TODO: Add real values here

$module-padding-sm: 50px;
$module-padding-md: 60px;
$module-padding-lg: 80px;
$module-padding-xl: 120px;

$footer-size: 0;
$footer-simple-size: 38px;
$nav-size: 45px;
$header-title-size: 50px;

:root {
  --viewportHeight: 100vh;
}
