// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import '../../../../styles/variables/_breakpoints.scss';
@import '../../../../styles/variables/_colors.scss';
@import '../../../../styles/variables/_spacers.scss';
@import '../../../../styles/variables/_typography.scss';
@import '../../../../styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import '../../../../styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import '../../../../styles/mixins/_breakpoints.scss';
@import '../../../../styles/mixins/_form-helpers.scss';

.input {
  position: relative;
  display: grid;
  place-content: center center;
  grid-template-columns: 100%;
}

.input_label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms;
  color: var(--secondary);
}

.input__empty {
  .input_label {
    opacity: 1;
  }
}

.input_realInput {
  border: none;
  text-align: center;
  border-bottom: 1px solid var(--secondary);
  padding-top: 20px;
  padding-bottom: 19px;
  transition: box-shadow 200ms, border 200ms;
  &:focus {
    outline: none;
    border-bottom-color: var(--primary);
  }
}

.input_error {
  color: var(--error);
}

.input_container__errorState {
  .input_realInput {
    border-color: var(--error);
  }
}
