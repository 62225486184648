// ===========================================================================
// *** Spacing (For consistent module padding)
// ===========================================================================

%module-padding,
.module-padding {
  padding: $module-padding-sm 0;

  @include breakpoint-up('sm') {
    padding: $module-padding-md 0;
  }

  @include breakpoint-up('lg') {
    padding: $module-padding-lg 0;
  }
}
