// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import '../../styles/variables/_breakpoints.scss';
@import '../../styles/variables/_colors.scss';
@import '../../styles/variables/_spacers.scss';
@import '../../styles/variables/_typography.scss';
@import '../../styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import '../../styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import '../../styles/mixins/_breakpoints.scss';
@import '../../styles/mixins/_form-helpers.scss';

.RadioContainer {
  height: 65px;
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--light-dark);
  }
  @include breakpoint-down('xs') {
    height: 60px;
  }
}
.Button {
  min-width: 15%;
  margin-top: 3rem;
  @include breakpoint-down('xs') {
    margin-top: 1rem;
  }
}

.MainContainerWithFooterFixed {
  @include breakpoint-down('xs') {
    margin-bottom: 9rem;
  }
}
