// ===========================================================================
// *** Content-based typography classes
// ===========================================================================

%typography-optimize-readability {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

// TODO: Add real values here

// Font family assignments
// =====================================================================
%font-rockwell-all {
  font-family: 'rockwell', serif;
}

%font-rockwell-bold {
  font-family: 'rockwell', serif;
  font-weight: 700;
}

%font-gibson-all {
  font-family: 'canada-type-gibson', sans-serif;
}

%font-gibson-semi-bold {
  font-family: 'canada-type-gibson', sans-serif;
  font-weight: 600;
}

%font-gibson-med {
  font-family: 'canada-type-gibson', sans-serif;
  font-weight: 500;
}
%font-gibson-light {
  font-family: 'canada-type-gibson', sans-serif;
  font-weight: 300;
}

// TODO: Include font file (waiting on client)
%font-proxima-nova-all {
  font-family: 'Proxima Nova', sans-serif;
}
%font-proxima-nova-normal {
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 400;
}
%font-proxima-nova-bold {
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 700;
}

// Custom classes | BEGIN
// ============================= ========================================
// ============================= ========================================
h1,
%h1,
.h1 {
  @extend %font-proxima-nova-normal;

  font-size: fontsize(20);
  line-height: lineheight(22, 20);
  letter-spacing: letterspacing(0.4, 20);
}
h2,
%h2,
.h2 {
  @extend %font-proxima-nova-bold;

  font-size: fontsize(14);
  line-height: lineheight(14, 14);
  letter-spacing: letterspacing(1.25, 14);
  text-transform: uppercase;
}
h3,
%h3,
.h3 {
  @extend %font-proxima-nova-normal;

  font-size: fontsize(14);
  line-height: lineheight(22, 14);
  letter-spacing: letterspacing(0.4, 14);
}

p,
%p1,
.p1 {
  @extend %font-proxima-nova-normal;

  font-size: fontsize(16);
  line-height: lineheight(22, 16);
  text-align: center;
}

i,
%p2,
.p2 {
  @extend %font-proxima-nova-normal;

  font-style: italic;
  font-size: fontsize(14);
  line-height: lineheight(14, 14);
  text-align: center;
  color: var(--light-dark);
}

small,
%p3,
.p3 {
  @extend %font-proxima-nova-normal;

  font-size: fontsize(12);
  line-height: lineheight(22, 12);
  letter-spacing: letterspacing(0.4, 12);
  color: var(--dark);
}

// ============================= ========================================
.txt-title {
  font-size: fontsize(20);
  line-height: lineheight(22, 20);
  letter-spacing: letterspacing(0.4, 20);
}

.txt-uppercase {
  text-transform: uppercase;
}

input,
button,
body,
%font-main-content,
.font-main-content {
  @extend %font-proxima-nova-normal;

  font-size: fontsize(16px);
  letter-spacing: letterspacing(0.18px);
  line-height: lineheight(22px, 16px);
  color: $dark;
}

%font-modal-title,
.font-modal-title {
  @extend %font-proxima-nova-bold;

  font-size: fontsize(14px);
  letter-spacing: letterspacing(1.25px);
  line-height: lineheight(14px, 14px);
}

%font-modal-content,
.font-modal-content {
  @extend %font-proxima-nova-normal;

  font-size: fontsize(14px);
  letter-spacing: letterspacing(0.4px);
  line-height: lineheight(22px, 14px);
}

%font-form-input,
.font-form-input {
  @extend %font-proxima-nova-normal;

  font-size: fontsize(20px);
  letter-spacing: letterspacing(0.222222px);
  line-height: lineheight(22px, 20px);
}

%font-interface-link-button,
.font-interface-link-button {
  @extend %font-proxima-nova-bold;

  font-size: fontsize(18px);
  letter-spacing: letterspacing(0.45px);
  line-height: lineheight(18px, 18px);
}

// Optimize readability
#{$all-type-elements} {
  @extend %typography-optimize-readability;
}
