// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import 'styles/variables/_breakpoints.scss';
@import 'styles/variables/_colors.scss';
@import 'styles/variables/_spacers.scss';
@import 'styles/variables/_typography.scss';
@import 'styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import 'styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import 'styles/mixins/_breakpoints.scss';
@import 'styles/mixins/_form-helpers.scss';

@import './styles/global.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--light);
}

body > #root {
  @include breakpoint-up(sm) {
    display: block;
    height: auto;
  }
}