// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import '../../../../styles/variables/_breakpoints.scss';
@import '../../../../styles/variables/_colors.scss';
@import '../../../../styles/variables/_spacers.scss';
@import '../../../../styles/variables/_typography.scss';
@import '../../../../styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import '../../../../styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import '../../../../styles/mixins/_breakpoints.scss';
@import '../../../../styles/mixins/_form-helpers.scss';

.standardModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: hsla(0, 0%, 0%, 0.2);
  opacity: 1;
  pointer-events: all;
  transition: opacity 200ms;
  z-index: $z-index-modal;
  &[aria-hidden="true"] {
    opacity: 0;
    pointer-events: none;
  }
}

.standardModalContainer {
  position: relative;
  max-width: 716px;
  background: white;
  border-radius: 10px;
  padding: 0 10px 25px;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  max-height: calc( 100% - 20px );
  @include breakpoint-up(sm) {
    @media screen and (min-height: 600px) {
      margin: 6.54296875vh auto 0;
      max-height: 74.21875vh;
    }
  }

  @include breakpoint-down(xs) {
    margin: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    border-radius: 0;
    padding-top: 25px;
  }
}

.standardModal_close {
  position: absolute;
  top: 0;
  right: 0;
  &[disabled] {
    opacity: 0.5;
  }

  @include breakpoint-down(xs) {
    display: none;
  }
}

.standardModal_toolbar {
  padding: 15px 45px;
  margin-bottom: 15px;

  @include breakpoint-down(xs) {
    display: none;
  }
}

.standardModal_toolbarTitle {
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}

.standardModal_content {
  overflow-y: auto;
}

.standardModal_actions {
  margin-top: 20px;
  text-align: center;
}

