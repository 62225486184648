// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import '../../../../styles/variables/_breakpoints.scss';
@import '../../../../styles/variables/_colors.scss';
@import '../../../../styles/variables/_spacers.scss';
@import '../../../../styles/variables/_typography.scss';
@import '../../../../styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import '../../../../styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import '../../../../styles/mixins/_breakpoints.scss';
@import '../../../../styles/mixins/_form-helpers.scss';

$dropdown-height: 30px;

.DropdownContainer {
  position: relative;
  height: 100%;
  color: inherit;

  > .Activator {
    display: inline-flex;
    align-items: center;
    text-align: center;
    min-height: $dropdown-height;
    height: 100%;
    color: inherit;
    background: transparent;
    border-radius: 5px;
    border: 0;
    outline: 0;
    padding: 5px 10px;

    &:hover, &:active {
      background: var(--primary-dark);
      color: var(--light);
    }

    .Chevron {
      display: inline-block;
      margin-left: .35em;
      vertical-align: .255em;
      width: 15px;
    }
  }
  &.show {
    .Activator {
      background: var(--primary-dark);
      color: var(--light);
    }
  }

  .DropdownMenu {
    z-index: $z-index-dropdown;
    position: absolute;
    list-style: none;
    padding: 0;
    top: $dropdown-height + 15px; // no generic
    bottom: auto;
    left: 0;
    right: auto;
    margin: 0;
    background: var(--dark);
    border-radius: 0 0 5px 5px;

    &.right {
      right: 0;
      left: auto;
    }

    > li {
      min-width: 230px;
      position: relative;

      > .DropdownOption {
        display: block;
        width: 100%;
        padding: 12px 16px;
        color: var(--light);
        cursor: pointer;
        border: 0;
        outline: 0;
        background: transparent;

        &:hover {
          color: var(--light-light);
          background: var(--dark-light);
        }
      }
      &.DropdownMenuActive > a {
        color: var(--light-light);
        background: var(--dark-light);
      }
    }
  }
}
