// ===========================================================================
// *** Background colors
// ===========================================================================

// Generate all color utility classes: `.bg--[theme-color-name]` based on css variables
@mixin create-color-bg-classes($project-colors) {
  @each $theme-color-name, $hex-value in $project-colors {
    // Create helper class
    .bg--#{$theme-color-name} {
      background-color: var(--#{$theme-color-name});
    }
  }
}

@include create-color-bg-classes($project-colors);