// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import '../../styles/variables/_breakpoints.scss';
@import '../../styles/variables/_colors.scss';
@import '../../styles/variables/_spacers.scss';
@import '../../styles/variables/_typography.scss';
@import '../../styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import '../../styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import '../../styles/mixins/_breakpoints.scss';
@import '../../styles/mixins/_form-helpers.scss';

.modalContent {
  padding: 0 15px;
  p {
    text-align: justify;
  }
}
