// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import '../../../../styles/variables/_breakpoints.scss';
@import '../../../../styles/variables/_colors.scss';
@import '../../../../styles/variables/_spacers.scss';
@import '../../../../styles/variables/_typography.scss';
@import '../../../../styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import '../../../../styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import '../../../../styles/mixins/_breakpoints.scss';
@import '../../../../styles/mixins/_form-helpers.scss';

.Content {
  padding-left: 0;
  padding-right: 0;
}

.MainContainer {
  position: relative;
  background: var(--light-light);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  grid-row: span 2;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;

  @include breakpoint-up('sm') {
    grid-row: span 1;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.05);
  }
}

.MainContainer__forceNavbarMobile {
  grid-row: span 1;
  &.MainContainer__FooterSimple,
  &.MainContainer__FooterNone {
    grid-row: span 2;
  }
  &.MainContainer__FooterSimple {
    @include breakpoint-up('sm') {
      grid-row: span 1;
    }
  }
}

@function calculate_content_size($header, $footer) {
  @return calc(var(--viewportHeight) - #{$header} - #{$footer});
}

$footers: (('Full', $footer-size), ('Simple', $footer-simple-size), ('None', $footer-simple-size));

@each $footer in $footers {
  .MainContainer__Footer#{nth($footer, 1)} {
    $updatedFooter: 0;
    @if (nth($footer, 1) != 'Simple') {
      $updatedFooter: nth($footer, 2);
    }

    height: calculate_content_size(0, $updatedFooter);
    @include breakpoint-up(sm) {
      $updatedFooter: $footer-simple-size;
      @if (nth($footer, 1) == 'Simple') {
        $updatedFooter: nth($footer, 2);
      }

      height: calculate_content_size($nav-size, $updatedFooter);
    }
    &.MainContainer__forceNavbarMobile {
      $updatedFooter: 0;
      @if (nth($footer, 1) != 'Simple') {
        $updatedFooter: nth($footer, 2);
      }

      height: calculate_content_size($nav-size, $updatedFooter);
      @include breakpoint-up(sm) {
        $updatedFooter: $footer-simple-size;
        @if (nth($footer, 1) == 'Simple') {
          $updatedFooter: nth($footer, 2);
        }

        height: calculate_content_size($nav-size, $updatedFooter);
      }
    }
  }
}
