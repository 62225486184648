// ===========================================================================
// *** Z Indices
// ===========================================================================

// TODO: Add real values here

$z-index-submenu:       10;
$z-index-app-footer:    100;
$z-index-modal:         999;
$z-index-fab:           1000;
$z-index-dropdown:      1001;
