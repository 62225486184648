// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import '../../../../styles/variables/_breakpoints.scss';
@import '../../../../styles/variables/_colors.scss';
@import '../../../../styles/variables/_spacers.scss';
@import '../../../../styles/variables/_typography.scss';
@import '../../../../styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import '../../../../styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import '../../../../styles/mixins/_breakpoints.scss';
@import '../../../../styles/mixins/_form-helpers.scss';

.Button {
  padding: 0 30px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:disabled {
    cursor: not-allowed;
  }
}

@function transform_color_to_disabled_state($color) {
  $modifiedColor: $color;
  @if (saturation($modifiedColor) > 60) {
    $modifiedColor: desaturate($modifiedColor, 60%);
  }
  @if (lightness($modifiedColor) < 70) {
    $modifiedColor: lighten($modifiedColor, 30%);
  }
  @return $modifiedColor;
}

.Button__StylePillGlobal {
  background-color: var(--success);
  color: var(--light-light);
  border-radius: 20px;

  &:disabled {
    @each $theme-color-name, $hex-value in $project-colors {
      &.Button__Background#{$theme-color-name}Global {
        background: transform_color_to_disabled_state($hex-value);
      }
      // More specificity required
      &button.Button__Background#{$theme-color-name}Desktop {
        background: transform_color_to_disabled_state($hex-value);
      }
    }
  }

  &:not(:disabled) {
    &:hover,
    &:active {
      background: darken($success, 10%);
    }
  }
}
.Button__StylePillDesktop {
  @include breakpoint-up(sm) {
    background-color: var(--success);
    color: var(--light-light);
    border-radius: 20px;

    &:disabled {
      @each $theme-color-name, $hex-value in $project-colors {
        &.Button__Background#{$theme-color-name}Global {
          background: transform_color_to_disabled_state($hex-value);
        }
        // More specificity required
        &button.Button__Background#{$theme-color-name}Desktop {
          background: transform_color_to_disabled_state($hex-value);
        }
      }
    }

    &:not(:disabled) {
      &:hover,
      &:active {
        background: darken($success, 10%);
      }
    }
  }
}

.Button__StyleLinkGlobal {
  background: none;

  &:hover,
  &:active {
    background: none;
  }
}
.Button__StyleLinkDesktop {
  @include breakpoint-up(sm) {
    background: none !important;

    &:hover,
    &:active {
      background: none !important;
    }
  }
}

@each $theme-color-name, $hex-value in $project-colors {
  // Create helper class
  .Button__Color#{$theme-color-name}Global {
    // &.Button__StyleLinkGlobal {
      color: var(--#{$theme-color-name});
      &:disabled {
        color: transform_color_to_disabled_state($hex-value);
      }
    // }
  }
  .Button__Background#{$theme-color-name}Global {
    &.Button__StylePillGlobal {
      background: var(--#{$theme-color-name});
    }
  }
  .Button__HoverBackground#{$theme-color-name}Global {
    &.Button__StylePillGlobal {
      &:not(:disabled) {
        &:hover,
        &:active {
          background: darken($hex-value, 10%);
        }
      }
    }
  }
  .Button__HoverColor#{$theme-color-name}Global {
    &.Button__StyleLinkGlobal {
      &:not(:disabled) {
        &:hover,
        &:active {
          color: darken($hex-value, 10%);
        }
      }
    }
  }
  @include breakpoint-up(sm) {
    // More specificity required
    button.Button__Color#{$theme-color-name}Desktop {
      // &.Button__StyleLinkDesktop {
        color: var(--#{$theme-color-name});
        &:disabled {
          color: transform_color_to_disabled_state($hex-value);
        }
      // }
    }
    // More specificity required
    button.Button__Background#{$theme-color-name}Desktop {
      &.Button__StylePillDesktop {
        background: var(--#{$theme-color-name});
      }
    }
    // More specificity required
    button.Button__HoverBackground#{$theme-color-name}Desktop {
      &.Button__StylePillDesktop {
        &:not(:disabled) {
          &:hover,
          &:active {
            background: darken($hex-value, 10%);
          }
        }
      }
    }
    // More specificity required
    button.Button__HoverColor#{$theme-color-name}Desktop {
      &.Button__StyleLinkDesktop {
        &:not(:disabled) {
          &:hover,
          &:active {
            color: darken($hex-value, 10%);
          }
        }
      }
    }
  }
}
