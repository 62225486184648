// ===========================================================================
// *** Shared
// ===========================================================================
// You should add here just styles that doesn't generate css until used
// because this file is included automatically in each of the components, so
// it's included multiple times.

// ===========================================================================
// *** Variables
// ===========================================================================

@import '../../../../styles/variables/_breakpoints.scss';
@import '../../../../styles/variables/_colors.scss';
@import '../../../../styles/variables/_spacers.scss';
@import '../../../../styles/variables/_typography.scss';
@import '../../../../styles/variables/_z-indices.scss';

// ===========================================================================
// *** Functions
// ===========================================================================

@import '../../../../styles/functions/_typography-helpers.scss';

// ===========================================================================
// *** Mixins
// ===========================================================================

@import '../../../../styles/mixins/_breakpoints.scss';
@import '../../../../styles/mixins/_form-helpers.scss';


$radio-checked-color: var(--primary);
$radio-border-color: var(--dark-light);
$radio-size: 40px;
$radio-checked-size: 25px;
$radio-ripple-size: 10px;
$radio-padding: 10px;

.RadioInput {
  margin: 0;
  display: flex;
  align-items: center;
  min-height: $radio-size;
  height: 100%;
  position: relative;

  > label {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 0 ($radio-checked-size + $radio-padding) 0 $radio-padding;
    margin-bottom: 0;
    cursor: pointer;
    height: 100%;
    vertical-align: bottom;
    line-height: 22px;
    font-size: 18px;
    width: 100%;
    color: var(--dark);

    &::after, &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      content: '';
      border-radius: 50%;
      height: $radio-checked-size;
      width: $radio-checked-size;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }

    &::after {
      right: $radio-padding;
      border: 2px solid $radio-border-color;
    }

    &::before {
      right: ($radio-checked-size / 2 - $radio-checked-size / 2) + $radio-padding;
      transform: scale(0);
      background-image: url("assets/check-icon.svg");
    }
  }

  > input[type="radio"] {
    display: none;

    &:checked + label::after :global {
      border-color: $radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }

    &:checked + label::before {
      transform: scale(1);
    }
  }
}
